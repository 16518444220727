$(document).ready(function () {
    $("#nrdocument").mask("999.999.999-99");
    $("#document").mask("99.999.999/9999-99");
    $("#phone").mask("(99) 99999-9999");
    $(".date").mask("99/99/9999");
    $('.money').mask("#.##0,00", { reverse: true });
});


function formataCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

limpa_formulário_cep = function () {
    document.getElementById('rua').value = ("");
    document.getElementById('bairro').value = ("");
    document.getElementById('cidade').value = ("");
    document.getElementById('uf').value = ("");
}

meu_callback = function (conteudo) {
    if (!("erro" in conteudo)) {
        document.getElementById('rua').value = (conteudo.logradouro);
        document.getElementById('bairro').value = (conteudo.bairro);
        document.getElementById('cidade').value = (conteudo.localidade);
        document.getElementById('uf').value = (conteudo.uf);
    } else {
        //CEP não Encontrado.
        limpa_formulário_cep();
        alert("CEP não encontrado.");
    }
}

pesquisacep = function (valor) {

    $(".end-segurados").show(500);
    document.getElementById("nradress").focus();

    var cep = valor.replace(/\D/g, '');
    if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
            document.getElementById('rua').value = "...";
            document.getElementById('bairro').value = "...";
            document.getElementById('cidade').value = "...";
            document.getElementById('uf').value = "...";

            var script = document.createElement('script');
            script.src = 'https://viacep.com.br/ws/' + cep + '/json/?callback=meu_callback';
            document.body.appendChild(script);

        } else {
            limpa_formulário_cep();
            alert("Formato de CEP inválido.");
        }
    } else {
        limpa_formulário_cep();
    }
};


paymentMethod = function (id) {
    if (id == 3) {
        $("#cardPay").show(500);

        document.getElementById('card_number').setAttribute('required', 'required');
        document.getElementById('card_holder_name').setAttribute('required', 'required');
        document.getElementById('month').setAttribute('required', 'required');
        document.getElementById('year').setAttribute('required', 'required');
        document.getElementById('card_cvv').setAttribute('required', 'required');
        document.getElementById('card_cpf').setAttribute('required', 'required');
    } else {
        $("#cardPay").hide("slow");

        document.getElementById('card_number').removeAttribute('required', 'required');
        document.getElementById('card_holder_name').removeAttribute('required', 'required');
        document.getElementById('month').removeAttribute('required', 'required');
        document.getElementById('year').removeAttribute('required', 'required');
        document.getElementById('card_cvv').removeAttribute('required', 'required');
        document.getElementById('card_cpf').removeAttribute('required', 'required');
    }
}

_cpf = function (cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
    add = 0;
    for (i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
        return false;
    add = 0;
    for (i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
        return false;
    return true;
}

validaCpf = function () {
    var cpf = document.getElementById('nrdocument').value

    if (!_cpf(cpf)) {
        $('#nrdocument').addClass('is-invalid');
        $("#alertCpf").show(500);
        $('#submitOrder').addClass('disabled');
    } else {
        $('#nrdocument').removeClass('is-invalid');
        $("#alertCpf").hide('show');
        $('#submitOrder').removeClass('disabled');
    }

    document.getElementById('nrdocument').value = formataCPF(cpf)
}

particlesJS("particles-js", {
    "particles":
    {
        "number": {
            "value": 8, "density":
                { "enable": true, "value_area": 400 }
        },
        "color": { "value": "#1b1e34" },
        "shape": {
            "type": "polygon",
            "stroke": { "width": 0, "color": "#000" },
            "polygon": { "nb_sides": 6 },
            "image": { "src": "img/github.svg", "width": 100, "height": 50 }
        },
        "opacity": {
            "value": 0.3, "random": true,
            "anim": { "enable": false, "speed": 1, "opacity_min": 0.1, "sync": false }
        },
        "size": {
            "value": 160, "random": false,
            "anim": { "enable": true, "speed": 10, "size_min": 40, "sync": false }
        },
        "line_linked": { "enable": false, "distance": 200, "color": "#ffffff", "opacity": 1, "width": 2 },
        "move": {
            "enable": true, "speed": 8, "direction": "none", "random": false, "straight": false, "out_mode": "out", "bounce": false,
            "attract": { "enable": false, "rotateX": 600, "rotateY": 1200 }
        }
    },
    "interactivity": {
        "detect_on": "canvas",
        "events": {
            "onhover": { "enable": false, "mode": "grab" },
            "onclick": { "enable": false, "mode": "push" },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 400, "line_linked": { "opacity": 1 }
            },
            "bubble": { "distance": 400, "size": 40, "duration": 2, "opacity": 8, "speed": 3 },
            "repulse": { "distance": 200, "duration": 0.4 },
            "push": { "particles_nb": 4 }, "remove": { "particles_nb": 2 }
        }
    },
    "retina_detect": true
});
var count_particles, stats, update;
stats = new Stats; stats.setMode(0);
stats.domElement.style.position = 'absolute';
stats.domElement.style.left = '0px';
stats.domElement.style.top = '0px';
document.body.appendChild(stats.domElement);
count_particles = document.querySelector('.js-count-particles');
update = function () {
    stats.begin();
    stats.end();
    if (window.pJSDom[0].pJS.particles && window.pJSDom[0].pJS.particles.array) { count_particles.innerText = window.pJSDom[0].pJS.particles.array.length; }
    requestAnimationFrame(update);
}; requestAnimationFrame(update);
